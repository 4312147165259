import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import SendIcon from "@mui/icons-material/Send";

import LoadingSpinner from "../LoadingSpinner";

import CharityService, {
  Donation,
  Contribution,
} from "../../services/charity-service";
import { currencyFormatter, dateFormatter } from "./utils";

const svc = new CharityService();

const CurrentDonation: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [date, setDate] = useState<Date>(new Date());
  const [totalContributions, setTotalContributions] = useState<number>(0);
  const [daysContributed, setDaysContributed] = useState<number>(0);
  const [contribution, setContribution] = useState<string>();
  const [todaysContribution, setTodaysContribution] = useState<Contribution>();
  const [donation, setDonation] = useState<Donation>();

  const totalContributionsDollars = (): string => {
    return currencyFormatter.format(donation?.total_amount ?? 0);
  };

  const totalContributionTodayDollars = (): string => {
    return currencyFormatter.format(todaysContribution?.amount ?? 0);
  };

  const donationDate = () => {
    return dateFormatter.format(date);
  };

  const createDonation = () => {
    return svc.createDonation({ date: new Date() });
  };

  useEffect(() => {
    svc
      .listDonations()
      .then((donations) => {
        if (!donations.length) {
          return createDonation();
        }

        // Check if current donation for this month, if not, create one automatically
        if (
          dateFormatter.format(new Date(donations[0].date)) ===
          dateFormatter.format(new Date())
        ) {
          return donations[0];
        }

        return createDonation();
      })
      .then((donation) => setDonation(donation))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    parseDonation(donation);
  }, [donation]);

  const parseDonation = (donation?: Donation) => {
    if (!donation) {
      return;
    }

    setDate(new Date(donation.date));
    setTotalContributions(donation.total_amount);
    setDaysContributed(donation.contributions.length);

    const todaysContribution = donation.contributions.filter(
      (contribution) => new Date(contribution.date) !== new Date()
    );

    if (todaysContribution.length) {
      setTodaysContribution(todaysContribution[0]);
    } else {
      setTodaysContribution(undefined);
    }
  };

  const setContributionValue = (value: string) => {
    let onlyNums = value.replace(/[^0-9.-]/g, "");
    // Ensure only one set of decimal places
    if (onlyNums.split(".").length > 2) {
      const numberParts = onlyNums.split(".");
      onlyNums = numberParts[0] + "." + numberParts[1];
    }
    setContribution(onlyNums);
  };

  const addContribution = () => {
    if (!donation || !contribution) {
      return;
    }

    if (todaysContribution) {
      svc
        .updateContribution(donation.id, todaysContribution.id, {
          amount: (
            Number(contribution) + Number(todaysContribution.amount)
          ).toFixed(2),
        })
        .then(() => {
          svc.getDonation(donation.id).then((res) => setDonation(res));
          setContribution("");
        });
    } else {
      svc
        .addContribution(donation.id, {
          amount: Number(contribution),
        })
        .then(() => {
          svc.getDonation(donation.id).then((res) => setDonation(res));
          setContribution("");
        });
    }
  };

  const undoTodaysContribution = () => {
    if (!donation || !todaysContribution) {
      return;
    }

    svc.deleteContribution(donation.id, todaysContribution.id).then(() => {
      svc.getDonation(donation.id).then((res) => setDonation(res));
    });
  };

  // TODO for enter submit
  // const handleSubmit = (event: any) => {
  //   if (event.key == "Enter") {
  //     event.preventDefault();
  //     submitNewItem();
  //   }
  // };

  return (
    <Box>
      {isLoading && <LoadingSpinner />}
      <Box sx={{ mb: "5rem" }}>
        <Typography variant="h2" textAlign="center">
          Donation, {donationDate()}
        </Typography>
        {/* <Box display="flex" justifyContent="center">
          <Typography
            variant="h6"
            textAlign="center"
            sx={{ display: "inline" }}
          >
            This months charity:
          </Typography>{" "}
          <TextField
            variant="standard"
            sx={{ display: "inline", ml: ".5rem" }}
          ></TextField>
        </Box> */}
        <Typography variant="h6" textAlign="center">
          Total contribution this month: {totalContributionsDollars()}
        </Typography>
        <Typography variant="h6" textAlign="center">
          Total contribution today: {totalContributionTodayDollars()}
        </Typography>
        <Typography variant="h6" textAlign="center">
          Days contributed: {daysContributed}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" flexDirection="column">
        <Box display="flex" justifyContent="center">
          <TextField
            label="Add a contribution"
            focused
            value={contribution}
            onChange={(event) => setContributionValue(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  color="primary"
                  onClick={addContribution}
                  disabled={!contribution}
                >
                  <SendIcon />
                </IconButton>
              ),
            }}
          ></TextField>
        </Box>
        {todaysContribution && (
          <Box display="flex" justifyContent="center" sx={{ mt: "1rem" }}>
            <Fade in timeout={700}>
              <Button
                sx={{ minWidth: "100px" }}
                onClick={undoTodaysContribution}
              >
                Undo Today's Contribution ({totalContributionTodayDollars()})
              </Button>
            </Fade>
          </Box>
        )}
        <Box display="flex" justifyContent="center" sx={{ my: "1rem" }}>
          <Button
            href="/app/charity"
            LinkComponent={Link}
            sx={{ minWidth: "100px" }}
          >
            Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CurrentDonation;
