export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const dateFormatter = new Intl.DateTimeFormat("en-US", {
  month: "short",
  year: "numeric",
  timeZone: "UTC",
});

export const formatDate = (data: string | Date): string => {
  if (data instanceof Date) {
    return dateFormatter.format(data);
  }

  return dateFormatter.format(new Date(data));
};
