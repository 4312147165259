import React from "react";
import { Link } from "gatsby";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

function AppsHome() {
  return (
    <Box width="100%">
      <Box sx={{ py: "1rem", mb: "1rem" }}>
        <Typography textAlign="center" variant="h4">
          All Apps
        </Typography>
      </Box>
      <Box
        width="100%"
        sx={{ px: "1rem" }}
        display="frex"
        justifyContent="center"
      >
        <Stack spacing={2} maxWidth="400px" minWidth="100%">
          <Button
            component={Link}
            to="/app/accounting"
            variant="contained"
            fullWidth
          >
            Accounting
          </Button>
          <Button
            component={Link}
            to="/app/charity"
            variant="contained"
            fullWidth
          >
            Daily Donation
          </Button>
          <Button
            component={Link}
            to="/app/reminders"
            variant="contained"
            fullWidth
            disabled
          >
            Reminders
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default AppsHome;
