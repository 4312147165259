import ApiService, { CalloutResult } from "./api-service";

export type AccountingListType = AccountingDetailType[];

export type AccountingDetailType = {
  id: string;
  owner: number;
  date: string;
  items: {
    good_deeds?: AccountingItemType[];
    missed_opportunities?: AccountingItemType[];
    inspirations?: AccountingItemType[];
  };
};

export type AccountingItemType = {
  description: string;
};

class AccountingService {
  private svc: ApiService = new ApiService();

  public listLogs(): Promise<AccountingListType> {
    return this.svc.get<AccountingListType>("/accounting/").then((result) => {
      if (result.statusCode !== 200) {
        throw result.data.detail ?? result.data;
      }

      return result.data;
    });
  }

  public createLog(
    payload: Partial<AccountingDetailType>
  ): Promise<AccountingDetailType> {
    return this.svc
      .post<AccountingDetailType>(`/accounting/`, payload)
      .then((result) => {
        if (result.statusCode !== 201) {
          throw result.data.detail ?? result.data;
        }

        return result.data;
      });
  }

  public getLog(logId: string): Promise<AccountingDetailType> {
    return this.svc
      .get<AccountingDetailType>(`/accounting/${logId}/`)
      .then((result) => {
        if (result.statusCode !== 200) {
          throw result.data.detail ?? result.data;
        }

        return result.data;
      });
  }

  public updateLog(
    logId: string,
    payload: Partial<AccountingDetailType>
  ): Promise<AccountingDetailType> {
    return this.svc
      .put<AccountingDetailType>(`/accounting/${logId}/`, payload)
      .then((result: CalloutResult<AccountingDetailType>) => result.data);
  }

  public deleteLog(logId: string): Promise<number> {
    return this.svc
      .delete(`/accounting/${logId}/`)
      .then((result) => result.statusCode);
  }
}

export default AccountingService;
