import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import AccountingList from "./AccountingList";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

const AccountingHome: React.FC<any> = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ px: "1rem" }}>
      {props.children}
      <Box display="flex" justifyContent="center" sx={{ px: "10%" }}>
        <div style={{ marginTop: "1rem" }}>
          <Button onClick={() => setOpen(true)}>View All Logs</Button>
        </div>
        <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
          <AccountingList
            onSelect={() => {
              setOpen(false);
            }}
          />
        </Drawer>
      </Box>
    </Box>
  );
};

export default AccountingHome;
