import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";

type EditableListProps = {
  items: string[];
  color?: "primary" | "secondary";
  dispatch?: (records: string[]) => void;
  multiline?: boolean;
};

const EditableList: React.FC<EditableListProps> = ({
  items,
  color = "primary",
  dispatch,
  multiline = false,
}) => {
  const [newItemText, setNewItemText] = useState<string>("");

  const listItem = (text: string, key: number) => (
    <ListItem
      key={key}
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => deleteItem(key)}
        >
          <ClearIcon />
        </IconButton>
      }
    >
      <TextField
        variant="standard"
        value={items[key]}
        fullWidth
        color={color}
        onChange={(e) => updateItem(e.target.value, key)}
        multiline
      ></TextField>
    </ListItem>
  );

  const handleSubmit = (event: any) => {
    if (event.key == "Enter") {
      event.preventDefault();
      submitNewItem();
    }
  };

  const addItem = (newItem: string) => {
    if (dispatch && newItem) {
      dispatch([...items, newItem]);
    }
  };

  const updateItem = (newValue: string, index: number) => {
    if (dispatch) {
      const newArray = [...items];
      newArray[index] = newValue;
      dispatch(newArray);
    }
  };

  const deleteItem = (index: number) => {
    if (dispatch) {
      const newArray = [...items];
      newArray.splice(index, 1);
      dispatch(newArray);
    }
  };

  const submitNewItem = () => {
    addItem(newItemText);
    setNewItemText("");
  };

  return (
    <Box display="flex" flexDirection="column" height="90%" flex={1}>
      <List
        sx={{ width: "100%", bgcolor: "background.paper", flexGrow: 1, p: 0 }}
      >
        {items.map((item, index) => listItem(item, index))}
      </List>
      <ListItem
        sx={{ flex: 0, pb: 0, height: "auto" }}
        secondaryAction={
          <IconButton edge="end" aria-label="save" onClick={submitNewItem}>
            <SendIcon />
          </IconButton>
        }
      >
        <TextField
          variant="standard"
          label="Add new item"
          fullWidth
          onKeyDown={handleSubmit}
          value={newItemText}
          onChange={(e) => setNewItemText(e.target.value)}
          color={color}
          multiline={multiline}
        ></TextField>
      </ListItem>
    </Box>
  );
};

export default EditableList;
