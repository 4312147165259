import React from "react";
import { Link } from "gatsby";

import Box from "@mui/material/Box";

const Charity: React.FC = (props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="column"
      sx={{ px: "1rem" }}
    >
      {props.children}
    </Box>
  );
};

export default Charity;
