import React from "react";
import { Router } from "@reach/router";
import PrivateRoute from "../../components/PrivateRoute";
import AccountingHome from "../../components/accounting/AccountingHome";
import AccountingForm from "../../components/accounting/AccountingForm";
import AccountingDetail from "../../components/accounting/AccountingDetail";
import AppsHome from "../../components/AppsHome";
import CurrentDonation from "../../components/charity/CurrentDonation";
import Charity from "../../components/charity/Charity";
import CharityHome from "../../components/charity/CharityHome";

// TODO - make top level component a general container, and replace the form with the home page
function Apps() {
  return (
    <Router basepath="/app">
      <PrivateRoute path="/" component={AppsHome} />
      <PrivateRoute path="accounting" component={AccountingHome}>
        <PrivateRoute path=":logId" component={AccountingDetail} />
        <PrivateRoute path="/" component={AccountingForm} />
      </PrivateRoute>
      <PrivateRoute path="charity" component={Charity}>
        <PrivateRoute path="donate" component={CurrentDonation} />
        <PrivateRoute path=":donationId" component={CurrentDonation} />
        <PrivateRoute path="/" component={CharityHome} />
      </PrivateRoute>
    </Router>
  );
}

export default Apps;
