import ApiService from "./api-service";
import { formatDate } from "../utils/api-utils";

export type Contribution = {
  id: string;
  amount: number | string;
  date: string | Date;
};

export type Donation = {
  id: string;
  date: string | Date;
  status: string;
  total_amount: number;
  charity?: string;
  contributions: Contribution[];
  donation_sent: boolean;
};

export type DonationPayload = Partial<{
  date: string | Date;
  charity: string;
  donation_sent: boolean;
}>;

export type ContributionPayload = {
  amount: number | string;
};

class CharityService {
  svc: ApiService = new ApiService();

  public listDonations(): Promise<Donation[]> {
    return this.svc.get<Donation[]>("/donations/").then((res) => res.data);
  }

  public createDonation(data: DonationPayload): Promise<Donation> {
    this.formatDonationPayload(data);
    return this.svc.post<Donation>("/donations/", data).then((res) => res.data);
  }

  public getDonation(donationId: string): Promise<Donation> {
    return this.svc
      .get<Donation>(`/donations/${donationId}`)
      .then((res) => res.data);
  }

  public updateDonation(donationId: string, data: DonationPayload) {
    this.formatDonationPayload(data);
    return this.svc
      .patch<Donation>(`/donations/${donationId}/`, data)
      .then((res) => res.data);
  }

  public deleteDonation(donationId: string): Promise<number> {
    return this.svc
      .delete(`/donations/${donationId}/`)
      .then((result) => result.statusCode);
  }

  public addContribution(
    donationId: string,
    data: ContributionPayload
  ): Promise<Contribution> {
    return this.svc
      .post<Contribution>(`/donations/${donationId}/contributions/`, data)
      .then((res) => res.data);
  }

  public updateContribution(
    donationId: string,
    contributionId: string,
    data: ContributionPayload
  ) {
    return this.svc
      .patch<Contribution>(
        `/donations/${donationId}/contributions/${contributionId}/`,
        data
      )
      .then((res) => res.data);
  }

  public deleteContribution(
    donationId: string,
    contributionId: string
  ): Promise<number> {
    return this.svc
      .delete(`/donations/${donationId}/contributions/${contributionId}/`)
      .then((res) => res.statusCode);
  }

  private formatDonationPayload(payload: DonationPayload) {
    if (payload.date instanceof Date) {
      payload.date = formatDate(payload.date);
    }
  }
}

export default CharityService;
