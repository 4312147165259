import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import AccountingService from "../../services/accounting-service";
import Typography from "@mui/material/Typography";
import { navigate } from "@reach/router";
import {
  AccountingDetailType,
  AccountingListType,
} from "../../services/accounting-service";

type AccountingListProps = {
  onSelect?: () => any;
};

const svc: AccountingService = new AccountingService();

const AccountingList: React.FC<AccountingListProps> = ({
  onSelect,
}: AccountingListProps) => {
  const [records, setRecords] = useState<AccountingListType>([]);
  const [error, setError] = useState<String>("");

  const navToDetail = (logId: string) => {
    if (onSelect) {
      onSelect();
    }
    navigate(`/app/accounting/${logId}`);
  };

  useEffect(() => {
    svc
      .listLogs()
      .then((data) => {
        setRecords(data);
      })
      .catch((err) => {
        console.error("accountingListCmp", err);
        setError("Failed to fetch records, try again later.");
      });
  }, []);

  const formatListItem = (logData: AccountingDetailType): JSX.Element => {
    return (
      <MenuItem onClick={() => navToDetail(logData.id)} key={logData.id}>
        <ListItemText>{logData.date}</ListItemText>
      </MenuItem>
    );
  };

  return (
    <Box sx={{ width: "100%", pt: "1rem" }}>
      {error}
      <Typography variant="h6" textAlign="center" sx={{ pb: ".5rem" }}>
        All Logs
      </Typography>
      <Divider />
      <Paper elevation={0} sx={{ width: 320, maxWidth: "100%" }}>
        <MenuList>
          {records.map((log: AccountingDetailType) => formatListItem(log))}
        </MenuList>
      </Paper>
    </Box>
  );
};
export default AccountingList;
