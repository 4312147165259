import React, { useState, useEffect, useMemo } from "react";
import { navigate } from "gatsby";
import { RouteComponentProps } from "@reach/router";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import SettingsIcon from "@mui/icons-material/Settings";

import EditableList from "../EditableList";
import LoadingSpinner from "../LoadingSpinner";

import AccountingService, {
  AccountingDetailType,
  AccountingItemType,
} from "../../services/accounting-service";
import { debounce } from "../../utils";
import { formatSavePayload } from "../../utils/accounting-utils";

type AccountingDetailProps = RouteComponentProps & {
  logId?: string;
};

type SaveRecordInput = {
  recordId: string;
  goodDeeds: string[];
  missedOpportunities: string[];
  inspirations: string[];
};

const svc = new AccountingService();

export const dateFormatter = new Intl.DateTimeFormat("en-US", {
  day: "2-digit",
  month: "short",
  year: "numeric",
  timeZone: "UTC",
});

const AccountingDetail: React.FC<AccountingDetailProps> = ({ logId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [doDelete, setDoDelete] = useState(false);
  const [record, setRecord] = useState<Partial<AccountingDetailType>>({});
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [error, setError] = useState<string>("");
  const [goodDeeds, setGoodDeeds] = useState<string[]>([]);
  const [missedOpportunities, setMissedOpportunities] = useState<string[]>([]);
  const [inspirations, setInspirations] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const doSave = useMemo(() => {
    return debounce(
      (
        recordId: string,
        goodDeeds: string[],
        missedOpportunities: string[],
        inspirations: string[]
      ) => {
        const payload = formatSavePayload({
          goodDeeds,
          missedOpportunities,
          inspirations,
        });
        svc.updateLog(recordId, payload).then(() => {
          setIsUnsaved(false);
        });
      },
      1500
    );
  }, []);

  useEffect(() => {
    if (logId) {
      setIsLoading(true);
      svc
        .getLog(logId)
        .then((data) => {
          parseRecord(data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => setIsLoading(false));
    }
  }, [logId]);

  useEffect(() => {
    if (isUnsaved) {
      doSave(logId, goodDeeds, missedOpportunities, inspirations);
    }
  }, [goodDeeds, missedOpportunities, inspirations]);

  useEffect(() => {
    if (doDelete && logId) {
      svc.deleteLog(logId).then(() => {
        navigate("/app/accounting");
      });
    }
  }, [doDelete]);

  const parseItemList = (items: AccountingItemType[]): string[] => {
    return items.map((item) => item.description);
  };

  const parseRecord = (record: AccountingDetailType) => {
    setRecord(record);
    setGoodDeeds(parseItemList(record.items.good_deeds ?? []));
    setMissedOpportunities(
      parseItemList(record.items.missed_opportunities ?? [])
    );
    setInspirations(parseItemList(record.items.inspirations ?? []));
  };

  type AccountingColumn = {
    label: string;
    list: string[];
    dispatch: (records: string[]) => void;
  };

  const columns: AccountingColumn[] = [
    {
      label: "Mitzvahs",
      list: goodDeeds,
      dispatch: (records: string[]) => {
        setGoodDeeds(records);
        setIsUnsaved(true);
      },
    },
    {
      label: "Aveiras",
      list: missedOpportunities,
      dispatch: (records: string[]) => {
        setMissedOpportunities(records);
        setIsUnsaved(true);
      },
    },
    {
      label: "Divine Providence",
      list: inspirations,
      dispatch: (records: string[]) => {
        setInspirations(records);
        setIsUnsaved(true);
      },
    },
  ];

  const renderColumn = ({
    label,
    list,
    dispatch,
  }: AccountingColumn): JSX.Element => {
    return (
      <>
        <Grid
          xs={12}
          sm={4}
          paddingTop="2rem"
          textAlign="center"
          sx={{ mb: "2rem" }}
        >
          <Box sx={{ mb: "1rem" }}>
            <Typography variant="h6">{label}</Typography>
            <Divider />
          </Box>
          <EditableList
            items={list}
            dispatch={dispatch}
            multiline
            color="primary"
          />
        </Grid>
      </>
    );
  };

  const accountingLists = (
    <Card sx={{ px: "1rem", py: "1rem" }}>
      <Box textAlign="left" sx={{ pb: "2rem" }}>
        {/* Detail Header (Make into component later) */}
        <Grid container>
          <Grid xs={9}>
            <Box>
              <IconButton
                sx={{ verticalAlign: "baseline", p: ".25rem" }}
                onClick={() => navigate("/app/accounting")}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h4" sx={{ display: "inline", ml: ".5rem" }}>
                {record?.date && dateFormatter.format(new Date(record.date))}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={3}>
            <Box textAlign="right">
              <Button
                disabled={!isUnsaved}
                onClick={() =>
                  doSave(logId, goodDeeds, missedOpportunities, inspirations)
                }
                color="inherit"
              >
                <Typography variant="body1" sx={{ mr: ".25rem" }}>
                  {isUnsaved ? "Saving..." : "Saved"}
                </Typography>
                {isUnsaved ? <SaveIcon /> : <CheckIcon />}
              </Button>
              <IconButton onClick={handleSettingsClick}>
                <SettingsIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleSettingsClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setDoDelete(true);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {columns.map(renderColumn)}
        </Grid>
      </Box>
    </Card>
  );

  return (
    <Box justifyContent="center">
      {error}
      {isLoading ? <LoadingSpinner /> : accountingLists}
    </Box>
  );
};
export default AccountingDetail;
