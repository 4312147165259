import React, { FC } from "react";
import { navigate } from "gatsby";
import { useUserData } from "../state/user-context";
import { RouteComponentProps, useLocation } from "@reach/router";
import { redirectToLogin } from "../utils";

type PrivateRouteProps = RouteComponentProps & {
  component: React.ComponentType<any>;
  children?: any;
};

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  location = useLocation(),
  ...rest
}: PrivateRouteProps) => {
  const { state } = useUserData();

  if (!state.isAuthenticated && location.pathname !== `/login`) {
    redirectToLogin();
    return null;
  }
  return <Component {...rest} />;
};

export default PrivateRoute;
