import React, { useState } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CharityService, { Donation } from "../../services/charity-service";
import { currencyFormatter, formatDate } from "./utils";

type AllDonationsProps = {
  donations: Donation[];
};

const statusOptions = ["Open", "Complete"] as const;
type StatusOptions = typeof statusOptions[number];

const svc = new CharityService();

const AllDonations: React.FC<AllDonationsProps> = ({ donations = [] }) => {
  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const updateDonation = (donationId: string, status: StatusOptions) => {
    svc
      .updateDonation(donationId, { donation_sent: status === "Complete" })
      .then((result) => {
        console.log(result);

        const previousRecord = donations.findIndex(
          (rec) => rec.id === donationId
        );

        donations[previousRecord] = result;
      });
  };

  return (
    <Box>
      <Typography textAlign="center" variant="h6">
        All Donations
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Total Amount</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {donations.map((donation, index) => {
              return (
                <TableRow
                  hover
                  key={donation.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  // selected={isItemSelected}
                  // onClick={(event) => handleClick(event, row.name)}
                  // role="checkbox"
                  // aria-checked={isItemSelected}
                  tabIndex={-1}
                >
                  <TableCell>{formatDate(donation.date)}</TableCell>
                  <TableCell>
                    {currencyFormatter.format(donation.total_amount)}
                  </TableCell>
                  <TableCell>
                    <TextField
                      select
                      defaultValue={donation.status}
                      onChange={(evt) => {
                        updateDonation(
                          donation.id,
                          evt.target.value as StatusOptions
                        );
                      }}
                    >
                      {statusOptions.map((opt) => (
                        <MenuItem key={opt} value={opt}>
                          {opt}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AllDonations;
