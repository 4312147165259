import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import PastDonations from "./AllDonations";
import CharityService, { Donation } from "../../services/charity-service";

const svc = new CharityService();

const CharityHome: React.FC = () => {
  const [donations, setDonations] = useState<Donation[]>([]);

  useEffect(() => {
    svc.listDonations().then((donations) => setDonations(donations));
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={{ px: "1rem" }}
    >
      <Typography textAlign="center" variant="h2">
        Daily Donation
      </Typography>
      <Box display="flex" justifyContent="center">
        <Button
          href="/app/charity/donate"
          LinkComponent={Link}
          sx={{ my: "2rem" }}
          variant="contained"
        >
          Make a contribution
        </Button>
      </Box>
      <Box sx={{ my: "2rem" }}>
        <PastDonations donations={donations} />
      </Box>
    </Box>
  );
};

export default CharityHome;
