import AccountingService, {
  AccountingDetailType,
} from "../services/accounting-service";

import { formatDate } from "./api-utils";

const svc = new AccountingService();

type PayloadInput = {
  goodDeeds?: string[];
  missedOpportunities?: string[];
  inspirations?: string[];
  date?: Date;
};

export const formatSavePayload = ({
  goodDeeds,
  missedOpportunities,
  inspirations,
  date,
}: PayloadInput): Partial<AccountingDetailType> => {
  return {
    date: date && formatDate(date),
    items: {
      good_deeds:
        goodDeeds && goodDeeds.map((value) => ({ description: value })),
      missed_opportunities:
        missedOpportunities &&
        missedOpportunities.map((value) => ({
          description: value,
        })),
      inspirations:
        inspirations && inspirations.map((value) => ({ description: value })),
    },
  };
};

export const getTodaysLog = (): Promise<AccountingDetailType | null> => {
  const today = formatDate(new Date());

  return svc.listLogs().then((result) => {
    if (result.length && result[0].date === today) {
      return result[0];
    }
    return null;
  });
};
