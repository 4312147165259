import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { RouteComponentProps } from "@reach/router";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import AccountingService from "../../services/accounting-service";
import { formatSavePayload, getTodaysLog } from "../../utils/accounting-utils";

const svc = new AccountingService();

const AccountingForm: React.FC<RouteComponentProps> = (props) => {
  const [doCreate, setDoCreate] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [todaysLogId, setTodaysLogId] = useState("");

  useEffect(() => {
    if (doCreate) {
      const payload = formatSavePayload({ date: new Date() });
      svc
        .createLog(payload)
        .then((data) => {
          navigate(`/app/accounting/${data.id}`);
        })
        .catch((err) => {
          console.error(err);
          setError(err);
        });
    }
  }, [doCreate]);

  useEffect(() => {
    if (!todaysLogId) {
      getTodaysLog().then((result) => {
        console.log("TodaysLog", result);
        if (result) {
          setTodaysLogId(result.id);
        }
      });
    }
  }, []);

  const navToCurrentLog = () => {
    if (todaysLogId) {
      navigate(`/app/accounting/${todaysLogId}`);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      textAlign="center"
      flexDirection="column"
    >
      <Typography variant="h2" sx={{ mb: "2rem" }}>
        Accounting
      </Typography>
      {error}
      <div style={{ marginBottom: "1rem" }}>
        <Button
          variant="outlined"
          onClick={() => setDoCreate(true)}
          sx={{ width: 200 }}
        >
          Create New Log
        </Button>
      </div>
      <div>
        <Button
          disabled={!todaysLogId}
          onClick={navToCurrentLog}
          variant="contained"
          sx={{ width: 200 }}
        >
          Today's Log
        </Button>
      </div>
    </Box>
  );
};

export default AccountingForm;
