import { navigate } from "gatsby";
import { useLocation } from "@reach/router";

export const redirectToLogin = () => {
  const location = useLocation();
  if (location.pathname !== `/login`) {
    navigate(`/login?retURI=${encodeURI(location.pathname ?? "")}`);
  }
};

export const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};
